/* グローバルスタイル */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  line-height: 1.6;
  background-color: var(--background-color);
}

header {
  background-color: var(--header-background-color);
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: var(--header-text-color);
  margin: 0;
  font-size: 2rem;
}

/* アイキャッチ */
.eyecatch {
  width: 100%;
  /* margin-bottom: 0.5rem; */
}

.eyecatch img {
  width: 100%;
  height: auto;
}

.eye-catch-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

/* TopImage */

.top-image-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-top: 100vh; /* トップ画像の高さ分のマージンを追加 */
}

.content-container {
  max-width: 1200px; /* 適宜調整してください */
  margin: 0 auto;
  padding: 0 20px;
}

/* アテンションセクション */

.attention-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  padding: 1.5rem;
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.attention-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 1.5rem;
  margin: 2rem auto;
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.attention-image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.attention-text-container {
  margin-left: 1.5rem;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.attention-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.attention-button:hover {
  background-color: var(--primary-color);
  color: var(--button-text-color);
}

/* アバウトセクション */
.about-contents {
  display: flex;
  justify-content: center; /* 要素を中央に寄せる */
  align-items: flex-start;
  padding-top: 200px;
  padding-bottom: 100px;
  background-color: var(--about-background-color); /* カスタムプロパティを使用 */
  color: var(--text-color);
  margin-top: 30px;
  /* flex-wrap: wrap; 必要に応じて要素を折り返す */

}

/* .about-text-container {
  border-radius: 2%;
  flex-basis: 40%;
  padding: 20px;
  border-radius: 2%;
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
} */

/* .about-text {
  flex-basis: 40%;
  text-align: left;
  margin-bottom: 20px;
}

.about-text h1,
.about-text h2,
.about-text p {
  margin-bottom: 20px;
} */

.about-image {
  flex-basis: 60%;
  overflow: hidden;
  border-radius: 2%;
  width: 25%;
  height: 25%;
  margin: auto;
  /* margin-right: 8%; */
}

.about-image img {
  width: 100%; /* 画像をフレームに合わせる */
  height: auto; /* 高さを自動調整 */
  object-fit: cover; /* 画像をフレームに合わせて調整 */
}

@keyframes imageScroll {
  0% { left: 0; }
  50% { left: -100%; }
  100% { left: 0; }
}

.stylish-button {
  padding: 10px 20px;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 2px solid transparent;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.stylish-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 50px;
  z-index: -1;
  }

  .stylish-button:hover:before {
      background-color: var(--button-hover-color);
      opacity: 0.6;
    }
      
    .stylish-button:hover {
      color: var(--button-hover-text-color);
      border: 2px solid var(--button-hover-color);
    }
      
      /* ボタンに枠を一周させるアニメーションを追加 */
      .stylish-button:hover {
      animation: borderAnim 2s infinite;
      }
      
      @keyframes borderAnim {
      0% { border-color: #8e8e8e; }
      50% { border-color: black; } /* ホバー時の枠色を黒に変更 */
      100% { border-color: #007bff; }
      }

      @media (max-width: 768px) {
          .about-contents {
            flex-direction: column;
            padding-top: 100px; /* スマホ表示ではパディングを調整 */
            padding-bottom: 50px;
          }
        
          .about-text-container, .about-image {
            flex-basis: 80%; /* スマホ表示ではコンテナの幅を広げる */
            margin: 20px 0; /* 上下のマージンを設定して、要素間の距離を保持 */
          }
        
          .about-image {
            width: 50%; /* スマホ表示では画像のサイズを調整 */
            height: auto; /* 高さを自動で調整 */
          }
        }
        
        @media (max-width: 1024px) {
          .about-contents {
            padding-top: 150px;
            padding-bottom: 75px;
          }
        
          .about-text-container, .about-image {
            flex-basis: 60%; /* タブレット表示ではコンテナの幅をやや広げる */
          }
        
          .about-image {
            width: 40%; /* タブレット表示では画像のサイズをさらに調整 */
          }
        }

/* MoreAboutセクション */

.more-about-contents {
  display: flex;
  padding: 20px;
  padding-left: 50px;
  margin-top: -50px;
  background-color: var(--more-about-background-color); /* 個別の背景色に対応するカスタムプロパティを使用 */
}

.gallery-item {
  flex: 0 0 auto;
  width: 25%;
  margin-right: 20px;
  text-align: center;
  aspect-ratio: 1 / 1;
  color: var(--text-color); /* テキスト色をcolorconfigに合わせて設定 */
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.gallery-item h3 {
  margin-top: 10px;
  font-size: 1.5vw;
}

/* スマホ表示用のスタイル */
@media (max-width: 760px) {
  .more-about-contents {
      justify-content: center;
      padding: 20px;
      padding-left: 0;
      align-items: center;
  }

  .gallery-item {
      width: 30%;
      margin: 0 2.5%;
  }

  .gallery-item img {
      border-radius: 3px;
  }

  .gallery-item h3 {
      font-size: 2.5vw;
  }
}

/* ヒーローセクション */

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  position: relative;
  background: var(--hero-background-color); /* CSS変数を参照 */
}

.hero-logo {
  width: 120px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

.hero-ribbon {
  position: relative;
  background: linear-gradient(to bottom, var(--hero-ribbon-start), var(--hero-ribbon-end)); /* CSS変数を参照 */
  color: #FFF; /* 直接指定された値 */
  text-align: center;
  padding: 1.5em 0;
  width: 100%;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(201, 244, 253, 0.4), rgba(149, 209, 207, 0.1));
  z-index: -1;
}

.hero-content {
  text-align: center;
  margin-top: 50px;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-image-container {
  width: 50%;
  margin-top: 30px;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* ピックアップコンテンツセクション */

/* PickupContents.css */
.pickup-contents {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 0;
  margin-left: 15%; /* 左側にカード一つ分の余白を追加 */
}

.product-card {
  width: 25%; /* カードの幅を%で設定 */
  height: auto; /* 高さを自動に設定 */
  padding-bottom: 10%; /* カードの高さを保持 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  background-color: #fff; /* 背景色を設定 */
  padding-top: 5px; /* カード上面からの間隔 */
  padding-bottom: 1.5%; /* カード下部の余白を減らす */
  border-radius: 15px; /* 角の丸みを増やす */
}

.animation-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-image {
  width: 80%; /* 画像の幅を%で設定 */
  height: auto; /* 高さを自動に設定 */
  /* object-fit: cover; 画像のアスペクト比を維持 */
  margin: 3% auto; /* 画像の周りの余白を%で設定 */
  margin-top: 5px; /* イメージの上に間隔を設ける */
}

.product-name {
  margin: 5px 0 0; /* 商品名の下の間隔を狭くする */
  margin-bottom: 0; /* 商品名と価格の間の余白を狭くする */
  font-size: 2vw; /* フォントサイズをビューポート幅に基づいて設定 */
  text-align: center;
}

.product-price {
  text-align: center; /* 価格を中央寄せ */
  margin-bottom: 10px; /* 価格とボタンの間の余白を増やす */
  margin-top: 5px; /* 価格と画像の間の余白を調整 */
  font-size: 2vw; /* フォントサイズをビューポート幅に基づいて設定 */
}

.view-shop-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.animation-layer img {
  width: 100%; /* GIFアニメーションをカード幅最大に設定 */
  height: 100%;
  object-fit: cover; /* GIFをカバーするように調整 */
}

.product-card:hover .animation-layer {
  opacity: 1; /* マウスオーバー時にアニメーションを表示 */
}

.content-layer {
  position: relative;
  z-index: 1;
}

.product-name, .add-to-cart {
  color: black; /* デフォルトのテキスト色 */
  transition: color 0.3s ease;
}

.product-card:hover .product-name,
.product-card:hover .product-price,
.product-card:hover .add-to-cart {
  color: white; /* マウスオーバー時にテキスト色を変更 */
}

/* スマホ表示用のスタイル */
@media (max-width: 768px) {
  .pickup-contents {
      flex-wrap: wrap;
      justify-content: space-between; /* カードを均等に配置 */
      padding: 20px 20px; /* 左側に5%の余白を追加 */
      margin-left: 0; /* 左側にカード一つ分の余白を追加 */
  }

  .product-card {
      width: 45%; /* カードの幅を%で設定 */
      height: auto; /* 高さを自動に設定 */
      padding-bottom: 5%; /* カードの高さを保持 */
      margin: 0 1.5%; /* カードの左右に余白を設ける */

  }

  .product-image {
      width: 90%; /* 画像の幅を%で設定 */
      height: auto; /* 高さを自動に設定 */
      margin: 1.2% auto; /* 画像の周りの余白を%で設定 */
  }


  .product-name {
      top: 70%; /* 商品名の位置を%で設定 */
      font-size: 3vw; /* スマホ表示ではフォントサイズをビューポート幅に基づいて設定 */
  }

  .product-price {
      top: 80%; /* 価格の位置を%で設定 */
      font-size: 3vw; /* スマホ表示ではフォントサイズをビューポート幅に基づいて設定 */
  }

  .view-shop-button {
      font-size: 3.5vw; /* ボタンのフォントサイズをビューポート幅に基づいて設定 */
      padding: 4% 8%; /* ボタンのパディングを%で設定 */
      margin-top: 5%; /* ボタン上の余白を%で設定 */

  }
}

/* プロダクトコンテンツセクション */

.product-contents {
  padding: 20px 20px;
  display: flex;
  background-color: var(--background-color);
  color: var(--text-color);
  align-items: center;
}

.left-container, .right-container {
  flex: 1;
  padding: 20px;
}

.left-container .title {
  margin-bottom: 50px; /* タイトルとメインテキストの間隔 */
}

.left-container .main-text {
  font-size: 1.3em; /* メインテキストのサイズ */
  font-weight: bold; /* 太文字 */
  margin-bottom: 50px; /* メインテキストとサブテキストの間隔 */
}

.left-container .sub-text {
  font-size: 1.1em; /* サブテキストのサイズ */
  line-height: 1.7; /* 行間を広げる */
}

.left-container .main-text,
.left-container .sub-text {
  text-align: left; /* テキストを左揃えにする */
}

.right-container {
  position: relative;
  display: flex;
  justify-content: center; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
  height: 100%; /* 高さを100%に設定 */
}

.product-text-area {
  display: flex;
  flex-direction: column; /* 垂直方向のスタック */
  justify-content: center; /* 垂直方向の中央揃え */
  height: 100%; /* コンテナの高さを100%に設定 */
}

.image-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;

}

.frame {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.frame > img {
  width: 99%;
  height: 99%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: none;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  border-radius: 10px; /* 角丸をフレームに合わせる */
  transition: transform 0.3s ease, opacity 0.3s ease; /* スケールアニメーションを追加 */
}

.frame:hover .frame-overlay {
  display: flex;
  opacity: 1;
  transform: scale(1.05); /* フレームのオーバーレイを拡大 */
}

.frame:hover .frame-overlay .frame-overlay-text {
  color: rgb(255, 255, 255); /* ホバー時のテキスト色を黒に */
}

.frame:hover > img {
  transform: scale(1.05);
}

.circle {
  position: absolute;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease, transform 0.5s ease; /* アニメーション時間を延長 */
}

.circle.hidden {
  opacity: 0; 
  transform: scale(0.95); 
  pointer-events: none; /* 非表示時にマウスイベントを無効に */
  }

.circle-text {
color: black;
transition: transform 0.3s ease;
pointer-events: none;
}

.frame:hover > .circle {
  opacity: 0; /* フレームホバー時にサークルを透明に */
  transform: scale(1.1); /* サークルを少し拡大 */
}

/* 中画面デバイス用 */
@media screen and (max-width: 1080px) {
  .left-container .main-text {
      font-size: 1.1em; /* 中画面でのフォントサイズ */
  }

  .left-container .sub-text {
      font-size: 1em; /* 中画面でのフォントサイズ */
  }
}

@media screen and (max-width: 768px) {
.product-contents {
flex-direction: column; /* スマホ表示時に縦並びに */
}

.left-container, .right-container {
  width: 100%; /* スマホ表示時の幅調整 */
}

.left-container .main-text {
  font-size: 1em; /* 小画面でのフォントサイズ */
}

.left-container .sub-text {
  font-size: 0.9em; /* 小画面でのフォントサイズ */
}

}

/* フィーチャーセクション */

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;
  background-color: var(--section-background-color);
}

.feature {
  flex-basis: calc(33.333% - 40px);
  margin: 20px;
  text-align: center;
  max-width: 300px;
}

.feature i {
  font-size: 48px;
  color: var(--accent-color);
  margin-bottom: 20px;
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--text-color);
}

.feature p {
  font-size: 1rem;
  color: var(--text-light-color);
}

.feature img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
}

/* プロダクトセクション */

.products {
  padding: 50px;
  background-color: var(--section-background-color);
  text-align: center;
}

.products h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: var(--text-color);
}

.product-list {
  display: flex;
  justify-content: space-around; /* アイテム間に均等なスペースを設ける */
  flex-wrap: wrap;
}

.product {
  flex: 1 1 22%;
  max-width: 22%;
  margin: 1%;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.product h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--text-color);
}

.product p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: var(--text-light-color);
}

.product-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product-button:hover {
  background-color: var(--button-hover-color);
}

/* Galleryセクション */

/* GalleryContents.css */
.gallery-contents {
  padding: 20px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}

.gallery-contents h2 {
  font-size: 2em; /* メインテキストのサイズ */
  font-weight: bold; /* 太文字 */
  margin-bottom: 50px; /* メインテキストとサブテキストの間隔 */
  color: white;
}

/* PC表示での背景アレンジ */
@media (min-width: 769px) {
  .gallery-contents {
      background: linear-gradient(to right, white 10%, #212121 25%);
  }
}

/* 既存のスタイル */
.gallery-contents {
  padding: 20px;
  text-align: center;
  background-color: black; /* スマホ表示では背景は黒のまま */
}

.gallery-steps {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.step {
  position: relative;
  flex: 1 1 15%; /* 各カードの基本的なフレキシビリティを設定 */
  max-width: calc(20% - 20px); /* 最大幅を設定して、余白を差し引いた値に */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 通常時の遷移も設定 */
}

.step-number {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.step img {
  width: 100%; /* 親要素に合わせて幅を調整 */
  aspect-ratio: 3 / 4; /* 縦長のアスペクト比を設定 */
  object-fit: cover; /* 画像のアスペクト比を保ちながら要素を埋める */
  border-radius: 5px;
}

.arrow {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* 円形にする */
  border: 2px solid #444; /* 円の縁を濃い色にする */
  transition: transform 0.5s ease;
  width: 50px; /* 円の大きさ */
  height: 50px; /* 円の大きさ */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* 円に影を加える */
  color: #444;
  transition: color 0.5s ease;
}

/* 枠の周りを光が回るアニメーション定義 */
@keyframes rotate-light {
  0% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 1.5px 0 2px white; /* 光の位置を枠に近づける */
  }
  10% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 1px 0.5px 2px white;
  }
  20% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.5px 1px 2px white;
  }
  30% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 1.5px 2px white;
  }
  40% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.5px 1px 2px white;
  }
  50% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -1px 0 2px white;
  }
  55% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.8px -0.8px 2px white;
  }
  60% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.5px -1px 2px white;
  }
  65% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), -0.2px -1.2px 2px white;
  }
  70% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 -1.5px 2px white;
  }
  75% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.2px -1.2px 2px white;
  }
  80% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.5px -1px 2px white;
  }
  90% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0.5px -1px 2px white;
  }
  100% {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 1px -0.5px 1px rgba(255, 255, 255, 0); /* 光をフェードアウトさせる */
  }
}

.arrow.hovered {
  animation: rotate-light 2s linear 1; /* 両方のアニメーションを同時に1回のみ実行 */
  color: #bbb; /* ホバー時の矢印の色を変更 */
}

/* 画像カードのホバーアクション */
.step:hover {
  transform: scale(1.05); /* ホバー時にカードが少し大きくなる */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* 影を追加 */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
  .gallery-steps {
      flex-direction: column;
  }

  .step-number {
      top: 10px;
      left: 20px;
  }
  
  .arrow {
      transform: rotate(90deg); /* スマホ表示の場合は矢印を90度回転させる */
  }

  .step {
      flex-basis: 80%; /* スマホ表示では2列になるように調整 */
      max-width: 80%; /* 最大幅を設定して、画面幅の約半分に */
      padding: 10px;

  }
}

/* テストモニターセクション */


.testimonials {
  padding: 50px;
  background-color: var(--testimonial-background-color);
  text-align: center;
}

.testimonials h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: var(--testimonial-text-color);
}

.testimonial {
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--testimonial-text-color);
}

.user-name {
  font-size: 1rem;
  font-weight: bold;
  color: var(--testimonial-text-color);
}

/* メッセージセクション */

.message-box {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  font-family: 'Handlee', cursive;
  font-size: 18px;
  line-height: 1.6;
}

.message-content {
  white-space: pre-wrap;
}

/* CTAセクション */

.cta {
  padding: 50px;
  background-color: var(--cta-background-color);
  text-align: center;
}

.cta h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: var(--cta-text-color);
}

.cta-button {
  background-color: var(--cta-button-background-color);
  color: var(--cta-button-text-color);
  border: 2px solid var(--cta-button-background-color);
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: var(--cta-button-hover-color);
  color: var(--cta-button-text-color);
}

footer {
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.box {
  width: 200px;
  height: 200px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.ribbon {
  position: relative;
  background: #7B68EE;
  color: #fff;
  text-align: center;
  padding: 1em 2em; 
  overflow: hidden;
  width: max-content;
}

.ribbon::before, .ribbon::after {
  position: absolute;
  top: 0;
  content: '';
  display: block;
  border: 5em solid #6a5acd;
  z-index: -1;
}

.ribbon::before {
  border-right-color: transparent;
  border-left-color: transparent;
  right: 0;
}

.ribbon::after {
  border-left-color: transparent;
  border-right-color: transparent;
  left: 0;
}

.image-container {
  width: 100%;
  padding-bottom: 56.25%; /* 16:9のアスペクト比の場合 */
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

@media (max-width: 768px) {
  .feature {
    flex-basis: calc(50% - 40px); /* 画面が狭い場合は2つのアイテムを1行に収める */
  }
}

@media (max-width: 480px) {
  .feature {
    flex-basis: 100%; /* さらに画面が狭い場合は1つのアイテムを1行に収める */
    max-width: none; /* 最大幅の制限を解除 */
  }
}