.top-image {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../svg/main.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* .character {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  animation: moveCharacter 8s linear infinite, walkCharacter 0.7s ease-in-out infinite alternate;
} */

/* 上下に動くキャラクター */

/* .character {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: moveCharacter 2s infinite alternate;
}

@keyframes moveCharacter {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(-20px);
  }
} */

/* 左右に動くキャラクター */

/* .character {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  animation: moveCharacter 4s infinite;
}

@keyframes moveCharacter {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  25% {
    transform: translateX(-70%) translateY(-20px);
  }
  50% {
    transform: translateX(-50%) translateY(0);
  }
  75% {
    transform: translateX(-30%) translateY(-20px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
} */

/* 端から端へ動くキャラクター */

/* .character {
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
  animation: moveCharacter 4s linear infinite;
}

@keyframes moveCharacter {
  0% {
    left: -100px;
  }
  100% {
    left: calc(100% + 100px);
  }
} */

/* 端から端に歩いていくキャラクター */

/* .character {
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
  animation: moveCharacter 8s linear infinite, walkCharacter 0.7s ease-in-out infinite alternate;
} */

.character {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: auto;
  animation: moveCharacter 8s linear infinite, walkCharacter 0.7s ease-in-out infinite alternate;
}

@keyframes moveCharacter {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 200px); /* キャラクターの幅を考慮して終了位置を調整 */
  }
}

@keyframes walkCharacter {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .character {
    width: 150px;
    bottom: 15px;
  }
  
  @keyframes moveCharacter {
    0% {
      left: 0;
    }
    100% {
      left: calc(100% - 150px);
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .character {
    width: 120px;
    bottom: 10px;
  }
  
  @keyframes moveCharacter {
    0% {
      left: 0;
    }
    100% {
      left: calc(100% - 120px);
    }
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media screen and (max-width: 575px) {
  .character {
    width: 100px;
    bottom: 5px;
  }
  
  @keyframes moveCharacter {
    0% {
      left: 0;
    }
    100% {
      left: calc(100% - 100px);
    }
  }
}