/* EyeCatch.css */
.eye-catch {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  z-index: -1;
}

.eye-catch-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0, 0); /* 中心からの変位をリセット */
  opacity: 0; /* アニメーションの開始状態 */
  animation: fadeIn 1s forwards;
  animation-delay: var(--animation-delay, 0s);
}


.eye-catch-layer svg {
  width: 100%;
  height: auto;
}

.artboard-2 {
  z-index: 5;
  transition: transform 0.5s ease-out;
  --animation-delay: 0.2s;
}

.artboard-4 {
  z-index: 4;
  --animation-delay: 0.4s;
}

.artboard-5 {
  z-index: 3;
  --animation-delay: 0.6s;
}

.artboard-6 {
  z-index: 2;
  transition: transform 0.5s ease-out;
  --animation-delay: 0.8s;
}

.artboard-3 {
  z-index: 1;
  transition: transform 0.5s ease-out;
  --animation-delay: 1s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}